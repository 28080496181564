import React, { useContext } from "react"
import Hero from "~/components/configurable/Hero"
import Block from "~/components/configurable/Block"
import Heading from "~/components/configurable/Heading"
import BlockCTA from "~/components/configurable/BlockCTA"
import Col6 from "~/components/grid/Col6"
import { window } from 'global';
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import "./blank.scss"

const Sitemap = ({ location }) => {

  return (
    <div className="blank">
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
          Sitemap
        </Heading>
      </Hero>
      <Block className="segBlock">
        <Section>
          <div className="container container row ">
            <Col6>
                <Heading level={3} underlined>Company</Heading>
                <ul style={{marginBottom:30}} >
                <li>
                  <a href="/about/">
                     About 
                  </a>
                </li>
                <li>
                  <a href="/aftersales/">
                     Aftersales 
                  </a>
                </li>
                <li>
                  <a href="/certifications/">
                     Certifications 
                  </a>
                </li>
                <li>
                  <a href="/contact-us/">
                     Contact Us 
                  </a>
                </li>
                <li>
                  <a href="/covid-19/">
                     Covid 19 
                  </a>
                </li>
                <li>
                  <a href="/privacy/">
                     Privacy 
                  </a>
                </li>
                <li>
                  <a href="/quote/">
                     Quote 
                  </a>
                </li>
                </ul>
                <Heading level={3} underlined>For your businesss</Heading>
                <ul style={{marginBottom:30}} >
                <li>
                  <a href="/for-your-business/">
                     For Your Business 
                  </a>
                </li>
                <li>
                  <a href="/quote-commercial/">
                     Quote Commercial 
                  </a>
                </li>
                <li>
                  <a href="/service/asset-management/">
                     Service Asset Management 
                  </a>
                </li>
                <li>
                  <a href="/service/community-energy/">
                     Service Community Energy 
                  </a>
                </li>
                <li>
                  <a href="/service/industrial-commercial-solutions/">
                     Service Industrial Commercial Solutions 
                  </a>
                </li>
                <li>
                  <a href="/service/operation-maintenance/">
                     Service Operation Maintenance 
                  </a>
                </li>
                <li>
                  <a href="/service/technical-design/">
                     Service Technical Design 
                  </a>
                </li>
                </ul>
                <Heading level={3} underlined>For your community</Heading>
                <ul style={{marginBottom:30}} >
                <li>
                  <a href="/for-your-community/">
                     For Your Community 
                  </a>
                </li>
                <li>
                  <a href="/twobuytwo/">
                     Twobuytwo 
                  </a>
                </li>
                <li>
                  <a href="/solar-together-devon/">
                     Solar Together Devon 
                  </a>
                </li>
                <li>
                  <a href="/solar-together-london/">
                     Solar Together London 
                  </a>
                </li>
                <li>
                  <a href="/solar-together/">
                     Solar Together 
                  </a>
                </li>
                </ul><Heading level={3} underlined>Info Centre</Heading>
                <ul style={{marginBottom:30}} >
                <li>
                  <a href="/faq/">
                     Faq 
                  </a>
                </li>
                <li>
                  <a href="/seg/">
                     Seg 
                  </a>
                </li>
                <li>
                  <a href="/why-solar/">
                     Why Solar 
                  </a>
                </li>
                <li>
                  <a href="/accessories-info/">
                     Accessories
                  </a>
                </li>
                <li>
                  <a href="/batteries-info/">
                     Batteries
                  </a>
                </li>
                <li>
                  <a href="/inverters-info/">
                     Inverters
                  </a>
                </li>
                <li>
                  <a href="/mounting-info/">
                     Mounting
                  </a>
                </li>
                <li>
                  <a href="/panels-info/">
                     Panels
                  </a>
                </li>
                </ul>
              </Col6>
              <Col6>
              <Heading level={3} underlined>Blog</Heading>
                <ul style={{marginBottom:30}} >
                <li>
                  <a href="/blog/">
                     Blog 
                  </a>
                </li>
                <li>
                  <a href="/blog/calculating-your-savings/">
                     Blog Calculating Your Savings 
                  </a>
                </li>
                <li>
                  <a href="/blog/fifty-years-of-earth-day/">
                     Blog Fifty Years Of Earth Day 
                  </a>
                </li>
                <li>
                  <a href="/blog/have-you-found-yourself-working-from-home-all-of-a-sudden/">
                     Blog Have You Found Yourself Working From Home All Of A Sudden 
                  </a>
                </li>
                <li>
                  <a href="/blog/petrol-and-diesel-vehicles-to-be-banned-by-2035/">
                     Blog Petrol And Diesel Vehicles To Be Banned By 2035 
                  </a>
                </li>
                <li>
                  <a href="/blog/ten-point-plan-for-a-green-recovery/">
                     Blog Ten Point Plan For A Green Recovery 
                  </a>
                </li>
                <li>
                  <a href="/blog/the-green-energy-grant/">
                     Blog The Green Energy Grant 
                                        </a>
                </li>
                <li>
                  <a href="/blog/what-does-it-mean-for-you/">
                     Blog What Does It Mean For You 
                  </a>
                </li>
                <li>
                  <a href="/fire/">
                     Blog Let’s talk about Solar Safety
                  </a>
                </li>
                </ul>
                
                <Heading level={3} underlined>Projects</Heading>
                <ul style={{marginBottom:30}} >
                <li>
                  <a href="/projects/">
                     Projects 
                  </a>
                </li>
                <li>
                  <a href="/project/bay-solar-farm/">
                     Project Bay Solar Farm 
                  </a>
                </li>
                <li>
                  <a href="/project/broad-oak-farm/">
                     Project Broad Oak Farm 
                  </a>
                </li>
                <li>
                  <a href="/project/clapham-solar-farm/">
                     Project Clapham Solar Farm 
                  </a>
                </li>
                <li>
                  <a href="/project/easy/">
                     Project Easy 
                  </a>
                </li>
                <li>
                  <a href="/project/kings-college-hospital/">
                     Project Kings College Hospital 
                  </a>
                </li>
                <li>
                  <a href="/project/no-surprises-and-just-happy-with-the-results/">
                     Project No Surprises And Just Happy With The Results 
                  </a>
                </li>
                <li>
                  <a href="/project/paddington-station/">
                     Project Paddington Station 
                  </a>
                </li>
                <li>
                  <a href="/project/professionals-in-every-aspect/">
                     Project Professionals In Every Aspect 
                  </a>
                </li>
                <li>
                  <a href="/project/prompt-with-setup-and-quick-with-installation/">
                     Project Prompt With Setup And Quick With Installation 
                  </a>
                </li>
                <li>
                  <a href="/project/sevenoaks-home/">
                     Project Sevenoaks Home 
                  </a>
                </li>
                <li>
                  <a href="/project/smooth-nice-and-fast/">
                     Project Smooth Nice And Fast 
                  </a>
                </li>
                <li>
                  <a href="/project/south-cambridgeshire-district-council/">
                     Project South Cambridgeshire District Council 
                  </a>
                </li>
                <li>
                  <a href="/project/top-marks-for-this-team/">
                     Project Top Marks For This Team 
                  </a>
                </li>
                <li>
                  <a href="/project/very-happy-with-the-service/">
                     Project Very Happy With The Service 
                  </a>
                </li>
                <li>
                  <a href="/project/very-happy-with-the-system-we-purchased/">
                     Project Very Happy With The System We Purchased 
                  </a>
                </li>
                <li>
                  <a href="/project/“a-fuss-free-experience.”/">
                     Project “a Fuss Free Experience.” 
                  </a>
                </li>
                <li>
                  <a href="/project/“we-are-really-happy”/">
                     Project “we Are Really Happy” 
                  </a>
                </li>
                <li>
                  <a href="/project/“we-couldn’t-be-happier-with-our-design”/">
                     Project “we Couldn’t Be Happier With Our Design” 
                  </a>
                </li>
                </ul>
                
            </Col6>
          </div>
        </Section>     
      </Block>
    </div>
  )
}

export default Sitemap
